<template>
  <div>
    <el-card
      class="ivy-question"
      :shadow="isEdit ? 'hover' : 'never'"
      style="margin:5px 0 30px 0"
    >
      <div class="question-content" style="margin-top:10px">
        <div>
          <template v-if="isEdit">
            <div style="float: right; padding: 3px 0">
              <el-tooltip
                class="item"
                effect="dark"
                content="Remove"
                placement="top"
                v-if="order > -1"
              >
                <el-button
                  type="danger"
                  size="mini"
                  @click="$emit('removeSection', order)"
                  :disabled="removeDisabled"
                >
                  <i class="fas fa-times-circle"></i>
                </el-button>
              </el-tooltip>
            </div>
          </template>
          <router-link
            v-if="composePracticeId && composePracticeId > 0"
            style="float: right; padding: 3px 0"
            :to="{
              name: 'SatPracticeFromFullDetail',
              params: {
                composePracticeId: composePracticeId
              },
              query: { id: practice.id }
            }"
          >
            <el-button type="success" size="mini" plain>
              <i class="fas fa-eye" />
            </el-button>
          </router-link>
          <router-link
            v-else
            style="float: right; padding: 3px 0"
            :to="{
              name: 'SatPracticeDetail',
              query: { id: practice.id }
            }"
          >
            <el-button type="success" size="mini" plain>
              <i class="fas fa-eye" />
            </el-button>
          </router-link>
        </div>
      </div>
      <div class="question-content-title">
        <kbd v-if="order">
          Module {{ order
          }}{{
            practice.subject ? `: ${titleCase(practice.subject.name)}` : ""
          }}
        </kbd>
        <div class="mt-3">
          <h4>
            <b><span v-html="practice.exam.title"></span></b>
          </h4>
          <!-- <h6  class="mb-3" style="color: #bbb9b9;">
            <b><span v-html="practice.exam.title"></span></b>
          </h6> -->
        </div>
        <p class="row mb-2" style="align-items:center;">
          <b>
            Questions:
            {{
              practice.exam.exam_questions
                ? practice.exam.exam_questions.length
                : practice.exam.exam_questions_count
            }}
          </b>
          <el-tooltip
            v-if="isEdit"
            class="ml-1"
            effect="dark"
            content="Add Questions"
            placement="top"
          >
            <router-link
              v-if="composePracticeId && composePracticeId > 0"
              style="float: right; padding: 3px 0"
              :to="{
                name: 'SatPracticeFromFullDetail',
                params: {
                  composePracticeId: composePracticeId
                },
                query: { id: practice.id, isEdit: 1 }
              }"
            >
              <el-button type="success" size="mini">
                <i class="fa fa-plus"></i>
              </el-button>
            </router-link>
            <router-link
              v-else
              style="float: right; padding: 3px 0"
              :to="{
                name: 'SatPracticeDetail',
                query: { id: practice.id, isEdit: 1 }
              }"
            >
              <el-button type="success" size="mini">
                <i class="fa fa-plus"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import { instant } from "@ivy-way/material";
import Common from "@/mixins/common.js";

export default {
  components: {},
  mixins: [Common],
  props: ["practice", "order", "isEdit", "composePracticeId", "removeDisabled"],
  data() {
    return {
      type: "passage",
      myPassage: 1
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  watch: {
    passage: {
      handler: function(newValue, oldValue) {
        // let type = "passage";
        this.myPassage = this.passage;
        if (this.passage === 0) {
          this.type = "single";
          this.myPassage = 1;
        } else {
          this.type = "passage";
          this.myPassage = this.passage;
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    moment() {
      return moment();
    }
  }
};
</script>

<style scoped>
::v-deep .el-button {
  margin: 0 0.3rem;
}
.passage-title {
  position: absolute;
  border: 1px solid #fefefe;
  background: var(--themeColor);
  font-size: 16px;
  color: white;
  left: -2px;
  top: -15px;
  height: 34px;
  padding: 0 17px;
  line-height: 32px;
  border-radius: 5px 5px 0 0;
}
.question-content {
  position: absolute;
  right: -5px;
  top: -25px;
}
::v-deep .question-content-title p {
  margin: 0;
}
.remove-question {
  cursor: pointer;
  position: absolute;
  font-size: 18px;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.tpos {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #edf1f8;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
}
.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;
  color: var(--themeColor);
}
</style>
<style scoped>
.question-order {
  margin: 10px 0 10px 0;
  background-color: #ccc;
}
kbd {
  padding-right: 15px;
  padding-left: 15px;
}
.answer_content {
  justify-content: space-around;
}
.cover {
  height: calc(100vh - 200px);
}
.question-order {
  margin: 20px 0;
  background-color: #ccc;
}
.ivy-question {
  position: relative;
  overflow: visible;
  background-color: #f8f8f8;
  height: 180px;
}
.ivy-question .el-card {
  overflow: visible;
}
.ivy-question-options {
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  font-size: 16px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  align-items: center;
  padding: 2px 0;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.ivy-tag {
  margin: 0 10px 10px 10px;
}
.hidden {
  display: none;
}
</style>
